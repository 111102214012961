import { mapGetters } from "vuex";
import { components } from "@/supported-components.json";
export const groupMenuMixin = {
  computed: {
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    isPaymentsEnabled() {
      return this.activeGroup.payments_enabled;
    },
    ...mapGetters([
      "getLeftMenu",
      "currentUser",
      "currentUserPermissions",
      "userRolesInGroup",
      "userGroupPolicies",
    ]),
    routes() {
      return this.$router.options.routes;
    },
    currentUserPolicies() {
      return this.groupPolicies.filter((policy) =>
        policy.group_roles.some((role) =>
          this.userRolesInGroup.includes(role.name),
        ),
      );
    },
    groupPolicies: {
      get() {
        return this.userGroupPolicies || [];
      },
      set(val) {
        this.$store.dispatch("setUserGroupPolicies", val);
      },
    },
  },
  methods: {
    groupMenu() {
      if (
        !this.getLeftMenu ||
        !this.getLeftMenu.destinations ||
        !this.getLeftMenu.destinations.length
      ) {
        return [];
      }

      const supportedComponents = components.filter(
        (el) =>
          el.menu_slug === "group_menu" &&
          this.hasAccess(el.route_name) &&
          el.enabled,
      );

      if (!supportedComponents) return [];

      const items = [];

      for (const destination of this.getLeftMenu.destinations) {
        const component = supportedComponents.find(
          (el) => el.entity === destination.component,
        );

        if (!component) continue;

        items.push({
          route: {
            name: component.route_name,
            params: {
              group_plugin_id: destination.group_plugin_id,
              ...JSON.parse(destination.params),
            },
          },
          name: destination.name,
          icon: destination.icon,
        });
      }

      const isAdmin = this.currentUser.roles.includes("admin");

      if (isAdmin) {
        items.push(
          {
            name: "Triggers",
            icon: "fas clock",
            route: { name: "triggers" },
            enabled: true,
          },
          {
            name: "Menus",
            icon: "fas list-alt",
            route: { name: "group_menu_list" },
            enabled: true,
          },
        );
      }

      return items;
    },

    hasAccess(routeName) {
      if (!routeName || !this.routes.length) return;

      const route = this.routes.find((route) => route.name === routeName);

      if (!route) return;

      if (
        (route.name === "group_transactions" ||
          route.name === "group_stripe_connected_account") &&
        !this.isPaymentsEnabled
      ) {
        return;
      }

      const isSuperAdmin = this.currentUser.roles.includes("admin");

      if (isSuperAdmin) return true;

      const permissions = route.meta.permissions;
      const groupPolicies = route.meta.groupPolicies;

      if (permissions && permissions.length > 0) {
        return permissions.every((permission) =>
          this.currentUserPermissions.includes(permission),
        );
      }

      if (groupPolicies && groupPolicies.length) {
        return groupPolicies.every((groupPolicy) =>
          this.currentUserPolicies
            .map((userPolicy) => userPolicy.name)
            .includes(groupPolicy),
        );
      }

      return true;
    },

    async listGroupPolicies() {
      try {
        const params = { per_page: 1000 };

        const response = await this.$api.groupPolicies.list(params);

        this.groupPolicies = response.data.data;
      } catch (error) {}
    },
  },
};
